require('../theme/fonts/feather/feather.min.css');
require('../theme/fonts/icomoon/icomoon.css');
require('select2/dist/css/select2.min.css');
require('../theme/css/theme.min.css');
require('bootstrap-star-rating/themes/krajee-svg/theme.min.css');
require('bootstrap-star-rating/css/star-rating.min.css');
require('../css/main.css');

const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../assets/js/dependencies/jsrouting-bundle/router.min.js';
global.Routing = Routing;
Routing.setRoutingData(routes);

const $ = require('jquery');
global.$ = global.jQuery = $;

require('bootstrap')
const List = require('list.js');
window.List = List;
require('select2/dist/js/select2.min');
require('../theme/js/theme.min.js');
const BootstrapDialog = require('../theme/js/bootstrap-dialog.min.js');
window.BootstrapDialog = BootstrapDialog;
const Cookies = require('js-cookie');
window.Cookies = Cookies;
require('jquery-form');
require('bootstrap-star-rating/js/star-rating.min');
require('bootstrap-star-rating/themes/krajee-svg/theme.min');
require('dirrty');

$(document).ready(function() {
    $('#loginModalForm').ajaxForm({
        dataType : 'json',
        beforeSubmit :  function(formData, jqForm, options) {
            $('.btn-submit', jqForm).html('Đăng nhập...').addClass('disabled');
        },
        success : function(responseText, statusText, xhr, $form) {
            if (responseText.status == 'success') {
                $('#userModal .alert').addClass('d-none');
                $('#userModal').modal('hide');

                window.location.reload();
            }
            else {
                $('#userModal .alert').removeClass('d-none').text(responseText.message);
                $('.btn-submit', $form).text('Đăng nhập').removeClass('disabled');
            }
        }
    });

    $.getJSON(Routing.generate('orn_api_v2_region', { 'group' : 0 }), function(data) {
        var data = data.data.regions;
        $.each(data, function(k, v) {
            var checked = '';
            if (v.id == getRegion())
                checked = '<span class="text-danger"><i class="fe fe-check-circle"></i></span>';

            $('#locationModal .list').append('<a href="' + generateRouteUrl('orn_web_cinema_region', { slug : v.slug }) + '" class="list-group-item d-flex justify-content-between align-items-center px-0 btn-change-region" data-region="' + v.id + '"><h4 class="text-body mb-0 name">' + v.name + '<span class="alias d-none">'+v.alias+'</span><span class="small text-muted d-block mt-1">' + v.cinemas + ' ' + Translator.trans('label.num_of_cinemas') + '</span></h4>' + checked + '</a>');
            regions[v.id] = {
                slug : v.slug,
                name : v.name
            }
        });

        var $lists = $('#locationModal');
        var options = {
            valueNames: ['name', 'alias'],
            listClass: 'list',
            searchClass: 'search'
        };
        new List($lists.get(0), options);

        displayCurrentLocation();
    });

    $('body').on('click', '.btn-change-region', function() {
        var region = $(this).data('region');

        if (getRegion() != region) {
            setRegion(region);
            var dialog = showLoadingDialog();
            window.location = generateRouteUrl('orn_web_cinema_region', { slug : regions[region].slug });

            displayCurrentLocation();
            $('#locationModal').modal('hide');
            dialog.close();
        }
        else {
            $('#locationModal').modal('hide');
        }
    });

    if ($('.sticky-movie-bars').length > 0) {
        bindStickyMovieBar();
    }

    $(document).on('change', '#cinemaModal .btn-select-region', function () {
        const currentRegion = JSON.parse(JSON.stringify(searchCinema)).region
        const selectedRegion = $('#cinemaModal .btn-select-region option:selected').val();

        if (currentRegion !== selectedRegion) {
            searchCinema.region = selectedRegion
            searchCinema.cinemas = [];
            getCinemas(1, true)
        }
    })

    $(document).on('keyup', '#cinemaModal .cinema-search', function() {
        handleFilterCinema();
    })

    const searchCinema = {
        region: getRegion(),
        cinemas: [],
        locale: document.documentElement.lang
    };

    function getCinemas(page = 1, search = false) {
        let baseUri = process.env.MOVEEK_API_HOST;
        let url = baseUri + `v1/cinemas?region.id=${searchCinema.region}&status=publish&closed=false&opening_soon=false&page_size=100&order[cineplex.name]=ASC&order[name]=ASC&page=${page}`;

        $.ajax({
            url: url,
            method: 'GET',
            success: function (response) {
                searchCinema.cinemas = [...searchCinema.cinemas, ...response]
                if (response.length === 100) {
                    page = page + 1;
                    getCinemas(page, search)
                } else {
                    searchCinema.cinemas = searchCinema.cinemas.map(function (cinema) {
                        if (cinema.cineplex == null) {
                            cinema.cineplex = {
                                id: 0,
                                name: 'Khác',
                                logo: {
                                    sizes: {
                                        'tiny': generateAssetUrl('/bundles/ornweb/img/no-thumbnail.png'),
                                        'square': generateAssetUrl('/bundles/ornweb/img/no-thumbnail.png')
                                    }
                                },
                                sponsored: false
                            }
                        }

                        return cinema;
                    })
                    searchCinema.cinemas.sort((x, y) => y.sponsored - x.sponsored || y.cineplex.sponsored - x.cineplex.sponsored);
                    appendCinemas(search)
                }
            }
        })
    }

    function appendCinemas(search) {
        $('#cinemaModal .list').html('')
        searchCinema.cinemas.forEach(cinema => {
            $('#cinemaModal .list').append(`<div class="border-bottom region-item"
                data-region="${cinema.region.id || ''}" data-name="${cinema.name || ''}"
                data-alias="${convertTextForSMS((cinema.name || '') + ' ' + (cinema.address || ''))}"
                data-address="${cinema.address || ''}">
                <div class="row align-items-center pl-4 pr-4 pb-3 pt-3 ">
                    <div class="col-auto">
    
                        <!-- Avatar -->
                        
                        <a href="${cinema.slug ? generateRouteUrl('orn_web_cinema_view', {slug: cinema.slug}) : ''}"
                           class="avatar avatar-sm">
                            <img src="${generateAssetUrl('/bundles/ornweb/img/no-thumbnail.png')}"
                                 data-src="${cinema.cineplex && cinema.cineplex.logo ? cinema.cineplex.logo.sizes.square : generateAssetUrl('/bundles/ornweb/img/no-thumbnail.png')}"
                                 alt="${cinema.name}" class="lazyload avatar-img rounded-circle">
                        </a>
    
                    </div>
                    <div class="col ml-n2">
    
                        <!-- Title -->
                        <h4 class="card-title mb-1">
                            <a href="${cinema.slug ? generateRouteUrl('orn_web_cinema_view', {slug: cinema.slug}) : ''}" class="name">${cinema.name || ''}</a>
                            ${cinema.adapter === 'ticketing' || cinema.adapter === 'affiliate' ? '<span class="badge badge-soft-primary">bán vé</span>' : ''}
                            ${cinema.adapter === 'voucher' ? '<span class="badge badge-soft-primary">bán voucher</span>' : ''}
                        </h4>
    
                        <!-- Time -->
                        <p class="card-text small text-muted address mb-0">
                            ${cinema.address || ''}
                        </p>
    
                        <span class="d-none alias">
                        ${convertTextForSMS((cinema.name || '') + ' ' + (cinema.address || ''))}
                    </span>
                    </div>
                </div>
            </div>`);
        })
        if (search) handleFilterCinema()
    }

    getCinemas()

    function convertTextForSMS(text) {
        text = text
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') // accent
            .replace(/[đĐ]/g, 'd')
            .replace(/([^\da-z-\s])/g, ' ') // special characters
            .replace(/-+|-/g, '') // chain -
            .replace(/^-+|-+$/g, '')  // - in head or tail
            .replace(/ỡ|õ|Ố|ỏ|ọ|ò|ó|ỏ|õ|ọ|ô|ồ|ố|ổ|ỗ|ộ|ơ|ờ|ớ|ở|ỡ|ợ|Ò|Ó|Ỏ|Õ|Ọ|Ô|Ồ|Ố|Ổ|Ỗ|Ộ|Ơ|Ờ|Ớ|Ở|Ỡ|Ợ/g, 'o')
            .replace(/ấ|ầ|ả|ạ|α|Ã|à|á|ả|ã|ạ|ă|ằ|ắ|ẳ|ẵ|ặ|â|ầ|ấ|ẩ|ẫ|ậ|À|Á|Ả|Ã|Ạ|Ă|Ằ|Ắ|Ẳ|Ẵ|Ặ|Â|Ầ|Ấ|Ẩ|Ẫ|Ậ/g, 'a')
            .replace(/ị|ì|í|ỉ|ĩ|ị|Ì|Í|Ỉ|Ĩ|Ị/g, 'i')
            .replace(/ể|ẹ|è|é|ẻ|ẽ|ẹ|ê|ề|ế|ể|ễ|ệ|È|É|Ẻ|Ẽ|Ẹ|Ê|Ề|Ế|Ể|Ễ|Ệ/g, 'e')
            .replace(/ữ|ũ|ủ|ụ|ù|ú|ủ|ũ|ụ|ư|ừ|ứ|ử|ữ|ự|Ù|Ú|Ủ|Ũ|Ụ|Ư|Ừ|Ứ|Ử|Ữ|Ự/g, 'u')
            .replace(/ỹ|ỳ|ý|ỷ|ỹ|ỵ|Y|Ỳ|Ý|Ỷ|Ỹ|Ỵ/g, 'y')
            .replace(/[”“]/g, '-')
            .replace(/`/g, '')
            .replace(/\s+/g, ' ') // space
        return text.toLowerCase().replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
        });
    }

    function handleFilterCinema() {
        let selectedRegion = $('#cinemaModal .btn-select-region').val();
        let search = $('#cinemaModal .cinema-search').val().toLowerCase();
        $('#cinemaModal .region-item').map((_, element) => {
            let region = $(element).data('region');
            let name = $(element).data('name').toLowerCase();
            let alias = $(element).data('alias').toLowerCase();
            let address = $(element).data('address').toLowerCase();
            
            if (region == selectedRegion
                && (name.indexOf(search) > -1 || alias.indexOf(search) > -1 || address.indexOf(search) > -1)
            ) {
                $(element).removeClass('d-none');
            } else {
                $(element).addClass('d-none');
            }
        })
    }

    $('body').on('click', '.btn-reveal-spoiler', function() {
        var btn = $(this);
        var block = btn.parents('.spoil-alert');
        $(btn).hide();
        $('.review-content', block).fadeIn(500);

        return false;
    });

    $('body').on('click', '.btn-do-like', function() {
        var btn = $(this);
        var id = btn.data('id');
        var token = btn.data('token');
        var value = btn.data('value');
        var group = btn.parents('.btn-actions');

        $('.btn-do-like', group).addClass('disabled');

        $.ajax({
            url: Routing.generate('orn_web_rating_like', { id : id }),
            method : 'POST',
            data: { _token : token, value : value },
            success : function(data) {
                if (data.msg == 'login')
                    $('#userModal').modal('show');
                if (data.msg == 'success') {
                    if (value == 1)
                        btn.addClass('btn-outline-success').removeClass('btn-outline-dark');
                    else
                        btn.addClass('btn-outline-danger').removeClass('btn-outline-dark');

                    $('.like-count', group)
                        .text((data.count > 0 ? '+' : '') + data.count)
                        .removeClass('d-none')
                        .removeClass('text-success')
                        .removeClass('text-danger')
                    ;

                    if (data.count > 0)
                        $('.like-count', group).addClass('text-success');
                    if (data.count < 0)
                        $('.like-count', group).addClass('text-danger');
                }
            }
        })

        return false;
    });

    $('body').on('click', '.btn-do-movie-like', function() {
        var btn = $(this);
        var token = btn.data('token');
        var id = btn.data('id');

        if (btn.hasClass('liked'))
            btn.removeClass('liked');
        else
            btn.addClass('liked');

        $.ajax({
            url : Routing.generate('orn_web_movie_action', { action : 'like', id : id }),
            method : 'POST',
            dataType : 'json',
            data : { _token : token },
            success : function(data) {
                if (data.msg == 'login') {
                    btn.removeClass('liked');
                    $('#userModal').modal('show');
                }
            }
        });

        return false;
    });

    $('#videoModal').on('show.bs.modal', function (event) {
        var btn = $(event.relatedTarget);
        var url = btn.data('video-url');
        var modal = $(this);

        dataLayer.push({
            'event' : 'virtualPageView',
            'virtualUrl' : btn.attr('href')
        });

        modal.find('.modal-body').html('<div class="js-video youtube widescreen"><iframe width="729" height="500" src="//www.youtube.com/embed/' + url + '?hd=1" frameborder="0" allowfullscreen></iframe></div>')
    })

    $('#videoModal').on('hidden.bs.modal', function (e) {
        $('#videoModal iframe').attr('src', $('#videoModal iframe').attr("src"));
    });

    $('#ratingModal').on('show.bs.modal', function (event) {
        var btn = $(event.relatedTarget);
        var url = btn.attr('href');
        var modal = $(this);

        $.get(url, function(html) {
            modal.find('.modal-content').html(html)
        });
    });

    $('#ratingModal').on('hidden.bs.modal', function () {
        var modal = $(this);

        modal.find('.modal-content').html('<div class="modal-body"> <div class="text-center"> <div class="text-muted spinner-border" role="status"> <span class="sr-only">Loading...</span> </div> </div> </div>')
    });

    $('body').on('click', '.is-disabled', function() {
        $('#errorModal .card-body .description').html('Tạm ngưng bán vé online do hệ thống bảo trì.<br />Hãy quay lại sau nha!');
        $('#errorModal').modal('show');

        return false;
    });

    $('body').on('click', '.is-nothing:not(.is-disabled)', function() {
        $('#errorModal .card-body .description').text('Cụm rạp này chưa được hỗ trợ bán vé tại Moveek.com.');
        $('#errorModal').modal('show');

        return false;
    });

    $('body').on('click', '.is-redirecting', function() {
        var btn = $(this);
        var url = btn.attr('href');

        window.location = url;

        return false;
    });

    $('body').on('click', '.is-phone-verification-needed', function() {
        $('#errorModal .card-body .description').html('<p class="mb-0">Trang cá nhân của tài khoản này chưa được kích hoạt.</p>');
        $('#errorModal').modal('show');

        return false;
    });
})

window.bindRating = function(point) {
    var $rating = $('#movie_rating_point');
    var $ratingForm = $('#movie_rating_form');
    var $ratingModal = $('#ratingModal');

    $rating.rating('create', {
        showClear: false,
        showCaption: false,
        theme: 'krajee-svg',
        stars: 10,
        min: 0,
        max: 10,
        step: 1,
        size: 'sm'
    });

    if (typeof point !== 'undefined') {
        $rating.rating('update', point);
    }

    var btnText;
    $ratingForm.ajaxForm({
        beforeSubmit : function(formData, jqForm, options) {
            btnText = $('.btn-submit', jqForm).text();
            $('.btn-submit', jqForm).html('Đang gửi...').attr('disabled', 'disabled').addClass('disabled');
            return true;
        },
        success : function(responseText, statusText, xhr, $form) {
            $('.btn-submit', $form).html(btnText).removeAttr('disabled').removeClass('disabled');
            if (responseText.status == 'success') {
                clearFormErrors($form);

                $ratingModal.modal('hide');

                $('#rating-point').text(responseText.data.rating);
                $('#rating-votes').text(responseText.data.votes);
                $('#rating-yours').text(responseText.data.yours);
                $('#rating-yours').parents('.btn').addClass('active');
                $('.movie-general-rating').rating('update', responseText.data.rating);
                $('.btn-do-movie-action[data-id="' + responseText.data.id + '"][data-action="watch"]').addClass('active');
                $('#msgModal .btn-other-ratings').attr('href', generateRouteUrl('orn_web_movie_review', { 'slug' : responseText.data.slug }));
                $('#msgModal').modal('show');
                setTimeout(function() {
                    $('#msgModal').modal('hide');
                }, 5000);
            }
            else if (responseText.status == 'unauthorized') {
                clearFormErrors($form);

                $('#userModal').modal('show');
                $('#userModal .alert-warning').removeClass('d-none');

                if (typeof responseText['target_url'] !== 'undefined') {
                    $('#loginModalForm input[name=_target_path]').val(responseText['target_url']);
                }
            }
            else
                displayFormErrors(responseText.errors, $form);
        }
    });

    $ratingForm.dirrty().on('dirty', function() {
        $('#approval-warning').removeClass('d-none');
    });
    $ratingForm.dirrty().on('clean', function() {
        $('#approval-warning').addClass('d-none');
    });
}

window.getRegion = function() {
    var currentRegion = Cookies.get('region');
    if (typeof currentRegion === "undefined")
        currentRegion = 1; // hcmc

    return currentRegion;
}

window.setRegion = function(region) {
    Cookies.set('region', region, { expires: 365 });
}

window.getGenre = function() {
    var currentGenre = Cookies.get('genre');
    if (typeof currentGenre === "undefined")
        currentGenre = 'all';

    return currentGenre;
}

window.setGenre = function(genre) {
    Cookies.set('genre', genre, { expires: 365 });
}

window.getLanguage = function() {
    var currentLanguage = Cookies.get('language');
    if (typeof currentLanguage === "undefined")
        currentLanguage = 'all';

    return currentLanguage;
}

window.setLanguage = function(language) {
    Cookies.set('language', language, { expires: 365 });
}

window.getSort = function() {
    var currentSort = Cookies.get('sort');
    if (typeof currentSort === "undefined")
        currentSort = 'popular';

    return currentSort;
}

window.setSort = function (sort) {
    Cookies.set('sort', sort, { expires: 365 });
}

window.doGetShowtimePrice = function(data) {
    var showtimes = [];
    $('.btn-showtime.is-show-price', $(data)).each(function (k, v) {
        showtimes.push($(v).data('reference'));
    });

    if (showtimes.length > 0) {
        $.ajax({
            url: Routing.generate('orn_web_showtime_price'),
            method : 'POST',
            data: { showtimes : showtimes },
            success : function(data) {
                $.each(data, function(k ,v) {
                    $('.btn-showtime[data-reference="' + k + '"]').append('<span class="amenity price">' + v + '</span>')
                });
            }
        })
    }
}

window.getCinema = function() {
    var currentCinema = Cookies.get('cinema');
    if (typeof currentCinema === "undefined")
        currentCinema = '';

    return currentCinema;
}

window.setCinema = function(cinema) {
    Cookies.set('cinema', cinema, { expires: 365 });
}

window.getTutorial = function(k) {
    var tutorial = Cookies.get('mvk_tutorial_' + k);
    if (typeof tutorial === "undefined")
        tutorial = 0;

    return tutorial;
}

window.setTutorial = function(k, v) {
    Cookies.set('mvk_tutorial_' + k, v, { expires: 365 });
}

window.delay = function(callback, ms) {
    var timer = 0;
    return function() {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

window.bindStickyMovieBar = function() {
    var block = $('.sticky-movie-bars');
    var top = block.offset().top;

    $(window).scroll(function () {
        var y = $(this).scrollTop();
        if (y >= top) {
            block.addClass('sticky');
            $('.main-content').addClass('has-sticky-header');
        }

        else {
            block.removeClass('sticky');
            $('.main-content').removeClass('has-sticky-header');
        }

    });
}

window.displayCurrentLocation = function() {
    var region = getRegion();
    $('.current-location').text(regions[region].name);
}

window.showLoadingDialog = function() {
    var dialog = BootstrapDialog.show({
        title: 'Manipulating Buttons',
        message: function() {
            var $content = $('<div class="text-center"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div><p class="mb-0">' + Translator.trans('label.redirecting') + '</p></div>');

            return $content;
        },
        buttons: []
    });
    dialog.setClosable(false);
    dialog.getModalHeader().hide();

    return dialog;
}

window.displayFormErrors = function(errors, $form) {
    clearFormErrors($form);

    for (var key in errors.fields) {
        var formInput = $('#' + key, $form);
        var formGroup = $('#' + key, $form).parents('.form-group');
        var formError = $('.invalid-feedback', formGroup);
        var blockError = $('<div class="invalid-feedback"><i class="fe fe-alert-circle"></i> ' + errors.fields[key] + '</div>');

        formInput.addClass('is-invalid');
        if (formError.length)
            formError.replaceWith(blockError);
        else
            formInput.after(blockError);
    }
}

window.clearFormErrors = function($form) {
    $('.form-group', $form).each(function(k,v) {
        $('.form-control', v).removeClass('is-invalid');
        $('.invalid-feedback', v).remove();
    });
}

global.generateRouteUrl = function(path, params) {
    return Routing.generate(path, params);
}

global.generateAssetUrl = function(path) {
    return cdn_base_uri + path;
}
